import * as React from 'react';
import { Typography } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { shorten, useRoofstockOnChainWeb3 } from '@rs-app/lib/web3';
import { DropDownMenu, DropDownMenuItem, MenuOption } from '@rs-common/DropDownMenu';
import { useRouter } from 'next/router';
import { getWallet, connectWallet, GetAnonIconImage } from '@rs-app/utils';
import { OnChainAnonymousIconOption } from '@rs-app/schema/schemaTypes';

export const ProfileMenu = () => {
  const { currentAccount, disconnectWeb3, openWeb3Modal } = useRoofstockOnChainWeb3();
  const router = useRouter();

  const [shouldRedirectToProfile, setShouldRedirectToProfile] = React.useState(false);
  const [anonIcon, setAnonIcon] = React.useState<OnChainAnonymousIconOption | null>(null);

  React.useEffect(() => {
    if (currentAccount) {
      setAnonIcon(null);
      getWallet(currentAccount).then(wallet => {
        if (wallet) {
          setAnonIcon(wallet.anonymousIcon);
        } else {
          connectWallet(currentAccount).then(wallet =>
            setAnonIcon(wallet?.anonymousIcon ?? OnChainAnonymousIconOption.KIWI)
          );
        }
      });
    }
  }, [currentAccount]);

  React.useEffect(() => {
    if (shouldRedirectToProfile && currentAccount && router.route !== '/profile') {
      router.push('/profile');
      setShouldRedirectToProfile(false);
    }
  }, [currentAccount, shouldRedirectToProfile, router]);

  const menuItems = [] as DropDownMenuItem[];

  if (currentAccount) {
    menuItems.push({
      displayLabel: 'Profile',
      linkPath: '/profile',
    } as DropDownMenuItem);
    menuItems.push({
      displayLabel: 'Log out',
      onClick: () => {
        disconnectWeb3();
        setAnonIcon(null);
      },
    } as DropDownMenuItem);
  } else {
    menuItems.push({
      displayLabel: 'Profile',
      onClick: () => {
        openWeb3Modal();
        setShouldRedirectToProfile(true);
      },
    } as DropDownMenuItem);
  }

  return (
    <DropDownMenu
      menuItems={menuItems}
      name="Profile"
      menuOption={
        currentAccount && anonIcon
          ? MenuOption.IconAndLabel
          : currentAccount && !anonIcon
          ? MenuOption.LabelOnly
          : MenuOption.IconOnly
      }
      buttonIcon={
        currentAccount && anonIcon ? <img src={GetAnonIconImage(anonIcon)} alt="" /> : <AccountCircleOutlined />
      }
      menuLabel={
        <span>
          <Typography variant="labelSmall">Hello,</Typography>
          <br />({shorten(currentAccount ?? '')})
        </span>
      }
    />
  );
};

import * as React from 'react';

import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { SnackbarProvider } from 'notistack';
import { publicConfig } from '@rs-app/lib/config';
import Head from 'next/head';
import { BecomeAMemberBanner } from './BecomeAMemberBanner';

type LayoutProps = React.PropsWithChildren<{
  headerText?: string;
  hideBecomeAMemberBanner?: boolean;
  hideCloseButton?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  minimalHeader?: boolean;
  onCloseMinimalHeader?: () => void;
  showHead?: boolean;
  showHeaderText?: boolean;
}>;

export const Layout: React.FC<LayoutProps> = ({
  children,
  headerText,
  hideBecomeAMemberBanner,
  hideFooter,
  hideHeader,
  hideCloseButton,
  minimalHeader,
  onCloseMinimalHeader = () => {
    window.location.assign(`${publicConfig.routes.root}/`);
  },
  showHead = true,
  showHeaderText,
}) => {
  const title = 'Roofstock onChain';
  const description = 'Buy and sell single-family homes in minutes.';
  const url = publicConfig.rsOnChain.baseUrl;
  const image = publicConfig.imagesFolder + '/OgImage.png';
  const domain = 'onchain.roofstock.com';
  return (
    <>
      {showHead && (
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={domain} />
          <meta property="twitter:url" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
        </Head>
      )}
      {!hideHeader && (
        <Header
          headerText={headerText}
          hideCloseButton={hideCloseButton}
          minimalHeader={minimalHeader}
          onCloseMinimalHeader={onCloseMinimalHeader}
          showHeaderText={showHeaderText}
        />
      )}
      {!hideBecomeAMemberBanner && <BecomeAMemberBanner />}
      <SnackbarProvider>
        {children}
        {!hideFooter && <Footer />}
      </SnackbarProvider>
    </>
  );
};

export const getLayout = (page, props?: LayoutProps) => {
  return <Layout {...props}>{page}</Layout>;
};

import * as React from 'react';

import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Container } from '@rs-app/components/Common/Container';
import styled from 'styled-components';
import { publicConfig } from '@rs-app/lib/config';
import EmailIcon from '@mui/icons-material/Email';
import { openChiliPiperSchedulingWindow } from '@rs-app/utils/chiliPiperUtils';

const FooterGridStyled = styled(Grid)`
  && {
    color: ${p => p.theme.palette.text.white};

    .tabSection {
      span {
        font-size: 13px;
      }
    }
    .contractAddress {
      background: linear-gradient(90deg, #fa8e36 1.54%, #ce8dc7 96.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 900;
      font-size: 16px;

      ${p => p.theme.breakpoints.down('sm')} {
        font-size: 13px;
      }
    }
  }
`;

const DividerStyled = styled(Divider)`
  && {
    margin: 3rem auto;
    background: #424c60;
  }
`;

export const Footer = () => {
  return (
    <Box component="footer" bgcolor={theme => theme.palette.slate.p900} padding="80px 0" role="contentinfo">
      <Container>
        <FooterGridStyled container>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography component="h2" variant="h6">
                  Ready for the next step?
                </Typography>
                <Typography component="p" marginTop="1rem" maxWidth="595px" variant="subtitle2Semibold">
                  Learn more about buying a home onChain and the Roofstock onChain protocol by talking to a team member
                </Typography>
                <Stack
                  alignItems="center"
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                  gap="2rem"
                  marginTop="2rem"
                >
                  <Box>
                    <Button href="mailto:onchain@roofstock.com" startIcon={<EmailIcon />} variant="outlined">
                      Contact our team
                    </Button>
                  </Box>
                  <Box display="flex" gap="1rem">
                    <IconButton
                      aria-label="Roofstock onChain GitHub"
                      disableRipple
                      href="https://github.com/RoofstockOnChain"
                    >
                      <img src={`${publicConfig.imagesFolder}/footer/Icon_GitHub_60x60.svg`} alt="" width="60px" />
                    </IconButton>
                    <IconButton
                      aria-label="Roofstock onChain Discord"
                      disableRipple
                      href="https://discord.gg/RoofstockOnChain"
                    >
                      <img src={`${publicConfig.imagesFolder}/footer/Icon_Discord_60x60.svg`} alt="" width="60px" />
                    </IconButton>
                    <IconButton
                      aria-label="Roofstock onChain Twitter"
                      disableRipple
                      href="https://twitter.com/rsonchain"
                    >
                      <img src={`${publicConfig.imagesFolder}/footer/Icon_Twitter_60x60.svg`} alt="" width="60px" />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DividerStyled />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="uppercase" align="center">
                Verified smart contract address:{' '}
                <span className="contractAddress">{publicConfig.rsOnChain.hocContractAddress}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DividerStyled />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <img className="icon" src={`${publicConfig.imagesFolder}/home/Join_community_image.png`} alt="" />
            </Grid>
            <Grid item marginTop="4rem">
              {/* cspell:disable */}
              <Typography variant="body2">
                The offerings available through Roofstock onChain include non-fungible tokens referred to as Homes
                onChain, each representing a single-family property-owning limited liability company. Homes onChain are
                not offered through any real estate brokerage firm or agent and are not securities as defined by the
                U.S. Securities and Exchange Commission Act. Neither Roofstock onChain nor its agents, employees, or
                affiliates serve as your advisor, agent, broker, contracting agent, or fiduciary and do not provide, or
                offer to provide, financial, investment, legal, real estate, or tax advice.
              </Typography>
              <br />
              <Typography variant="body2">
                Transacting on the blockchain, trading cybercurrency, and holding real property through a limited
                liability company may all have tax implications. You agree that you are solely responsible for
                determining what, if any, taxes apply to your transaction.
              </Typography>
              <br />
              <Typography variant="body2">
                All Roofstock onChain transactions are facilitated by Smart Contracts deployed on the Ethereum
                blockchain. The Ethereum blockchain requires the payment of a transaction fee (a “Gas Fee”) for every
                transaction that occurs on the Ethereum blockchain, and thus every blockchain transaction occurring on
                the Platform. The value of the Gas Fee changes, often unpredictably, and is entirely outside of the
                control of Roofstock onChain. You agree that under no circumstances will a contract, agreement, offer,
                sale, bid, or other transaction be invalidated, revocable, retractable, or otherwise unenforceable on
                the basis that the Gas Fee for the given transaction was unknown, too high, or otherwise unacceptable.
              </Typography>
              <br />
              <Typography variant="body2">
                Illustrations represent artistic interpretation and rendering of key architectural and design elements,
                and are not exact replicas of property. Actual grading, landscaping, proportions, scale, or other
                features of property may differ from drawing.
              </Typography>
              {/* cspell:enable */}
            </Grid>
          </Grid>
        </FooterGridStyled>
      </Container>
    </Box>
  );
};

import * as React from 'react';
import { Box, Button, Divider, Link as MuiLink, Menu, MenuItem, Stack } from '@mui/material';
import { EventAction, EventItem, EventProduct, EventSection } from '@rs-app/lib/events/BaseEvents';
import Link from 'next/link';
import { ProfileMenu } from './ProfileMenu';
import { publicConfig } from '@rs-app/lib/config';
import useEventTracking from '@rs-app/lib/hooks/useEventTracking';
import { Container } from '@rs-app/components/Common/Container';
import { ExpandMore } from '@mui/icons-material';

export const ToolbarMain = () => {
  const { logFormattedEvent } = useEventTracking();
  return (
    <Container>
      <Stack columnGap="1rem" direction="row" justifyContent="space-between">
        <Stack alignItems="center" columnGap="2.5rem" direction="row" component="nav">
          <Box>
            <Link href="/" passHref>
              <MuiLink>
                <img
                  src={`${publicConfig.imagesFolder}/header/Logo_light.svg`}
                  alt="Roofstock onChain"
                  width="197px"
                  height="37px"
                />
              </MuiLink>
            </Link>
          </Box>
          <Stack
            columnGap="1rem"
            direction="row"
            divider={<Divider flexItem light orientation="vertical" sx={{ alignSelf: 'center', height: '1rem' }} />}
          >
            <Box>
              <Link href="/properties" passHref>
                <MuiLink
                  onClick={() => {
                    logFormattedEvent({
                      product: EventProduct.OnChain,
                      item: EventItem.Link,
                      action: EventAction.Clicked,
                      section: EventSection.Header,
                      itemName: 'Browse property NFTs',
                    });
                  }}
                  underline="hover"
                  variant="body1Bold"
                >
                  View Homes onChain
                </MuiLink>
              </Link>
            </Box>
            <Box>
              <MuiLink
                href="https://marketplace.roofstockonchain.com/"
                rel="noreferrer"
                target="_blank"
                underline="hover"
                variant="body1Bold"
              >
                Buy on Marketplace
              </MuiLink>
            </Box>
            <Box>
              <LearnButtonMenu />
            </Box>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          columnGap="1rem"
          direction="row"
          divider={<Divider flexItem light orientation="vertical" sx={{ alignSelf: 'center', height: '1rem' }} />}
        >
          <Box>
            <ProfileMenu />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

const LearnButtonMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const closeMenu = () => setAnchorEl(null);
  return (
    <>
      <Button
        aria-controls={open ? 'learn-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<ExpandMore />}
        id="learn-button"
        onClick={event => setAnchorEl(event.currentTarget)}
        variant="text"
        // Unfortunately, we have to deviate from the theme to match the size of the other links in the toolbar.
        sx={{ fontSize: '1rem' }}
      >
        Learn
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="learn-menu"
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'learn-button',
        }}
      >
        <MenuItem onClick={closeMenu}>
          <Link href="/mint" passHref>
            <MuiLink>Become a member</MuiLink>
          </Link>
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          <MuiLink
            href="https://roofstock-onchain.gitbook.io/learn-about-roofstock-onchain/guides/how-it-works"
            rel="noreferrer"
            target="_blank"
          >
            How it works
          </MuiLink>
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          <MuiLink
            href="https://roofstock-onchain.gitbook.io/learn-about-roofstock-onchain/faqs/buying"
            rel="noreferrer"
            target="_blank"
          >
            FAQs
          </MuiLink>
        </MenuItem>
      </Menu>
    </>
  );
};

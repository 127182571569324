import * as React from 'react';
import {
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { EventAction, EventItem, EventProduct, EventSection } from '@rs-app/lib/events/BaseEvents';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Link from 'next/link';
import { publicConfig } from '@rs-app/lib/config';
import useEventTracking from '@rs-app/lib/hooks/useEventTracking';
import { useRoofstockOnChainWeb3 } from '@rs-app/lib/web3';
import { useRouter } from 'next/router';

export const ToolbarMobile = () => {
  const { logFormattedEvent } = useEventTracking();
  const { currentAccount, disconnectWeb3, openWeb3Modal } = useRoofstockOnChainWeb3();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [shouldRedirectToProfile, setShouldRedirectToProfile] = React.useState(false);
  const router = useRouter();
  React.useEffect(() => {
    if (shouldRedirectToProfile && currentAccount && router.route !== '/profile') {
      router.push('/profile');
      setShouldRedirectToProfile(false);
    }
  }, [currentAccount, shouldRedirectToProfile, router]);

  const handleNavigate = (url: string) => {
    const urlEventObject = {
      '/properties': 'Browse property NFTs',
      '/profile': 'Profile',
      '/mint': 'Mint',
      '/faq': 'FAQs',
    };
    logFormattedEvent({
      product: EventProduct.OnChain,
      item: EventItem.Link,
      action: EventAction.Clicked,
      section: EventSection.Header,
      itemName: urlEventObject[url],
    });
    setOpenMobileMenu(false);
  };

  return (
    <>
      <Container>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Box>
            <Link href="/" passHref>
              <MuiLink>
                <img
                  src={`${publicConfig.imagesFolder}/header/Logo_light.svg`}
                  alt="Roofstock onChain"
                  width="197px"
                  height="37px"
                />
              </MuiLink>
            </Link>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setOpenMobileMenu(true);
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>
        </Stack>
      </Container>
      <Drawer anchor="left" open={openMobileMenu} style={{ zIndex: 99999 }} onClose={() => setOpenMobileMenu(false)}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" padding="1rem">
          <Box>
            <Link href="/" passHref>
              <MuiLink onClick={() => setOpenMobileMenu(false)}>
                <img
                  src={`${publicConfig.imagesFolder}/header/Logo_light.svg`}
                  alt="Roofstock onChain"
                  width="134px"
                  height="25px"
                />
              </MuiLink>
            </Link>
          </Box>
          <IconButton onClick={() => setOpenMobileMenu(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <List>
          <ListItem disablePadding>
            <Link href="/properties" passHref>
              <ListItemButton component="a" onClick={() => handleNavigate('/properties')}>
                <ListItemText primary="View Homes onChain" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton href="https://marketplace.roofstockonchain.com/" rel="noreferrer" target="_blank">
              <ListItemText primary="Buy on Marketplace" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <Link href="/mint" passHref>
              <ListItemButton component="a" onClick={() => handleNavigate('/mint')}>
                <ListItemText primary="Become a member" />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="https://roofstock-onchain.gitbook.io/learn-about-roofstock-onchain/guides/how-it-works"
              rel="noreferrer"
              target="_blank"
            >
              <ListItemText primary="How it works" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="https://roofstock-onchain.gitbook.io/learn-about-roofstock-onchain/faqs/buying"
              rel="noreferrer"
              target="_blank"
            >
              <ListItemText primary="FAQs" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="https://roofstock-onchain.gitbook.io/onchain-knowledge-base/"
              target="_blank"
              rel="noreferrer"
            >
              <ListItemText primary="Learn" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            {currentAccount ? (
              <Link href="/profile" passHref>
                <ListItemButton component="a" onClick={() => handleNavigate('/profile')}>
                  <ListItemText primary="Profile" />
                </ListItemButton>
              </Link>
            ) : (
              <ListItemButton
                onClick={() => {
                  setOpenMobileMenu(false);
                  openWeb3Modal();
                  setShouldRedirectToProfile(true);
                }}
              >
                <ListItemText primary="Profile" />
              </ListItemButton>
            )}
          </ListItem>
          {currentAccount && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  disconnectWeb3();
                  setOpenMobileMenu(false);
                }}
              >
                <ListItemText primary="Log out" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
};

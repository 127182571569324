import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Link from 'next/link';
import { publicConfig } from '@rs-app/lib/config';
import { Container } from '@rs-app/components/Common/Container';

type ToolbarMainProps = {
  onCloseHeaderEvent: () => void;
  hideCloseButton?: boolean;
  showHeaderText?: boolean;
  headerTitle?: string;
};

export const MinimalHeader: React.FC<ToolbarMainProps> = ({
  headerTitle,
  hideCloseButton,
  onCloseHeaderEvent,
  showHeaderText = true,
}) => {
  return (
    <Box bgcolor={theme => theme.palette.slate.p900}>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between" padding="1.5rem 0">
          <Grid item xs="auto">
            <Link href="/">
              <a style={{ textDecoration: 'none' }}>
                <img src={`${publicConfig.imagesFolder}/header/Logo_light.svg`} alt="Roofstock onChain" width={197} />
              </a>
            </Link>
          </Grid>
          <Grid item xs>
            <Hidden smDown={true}>
              {showHeaderText && (
                <Typography component="h1" letterSpacing="2px" variant="labelLarge" textAlign="center">
                  {headerTitle}
                </Typography>
              )}
            </Hidden>
          </Grid>
          {!hideCloseButton && (
            <Grid item xs="auto">
              <Box display="flex" justifyContent="flex-end" width={{ sm: 197 }}>
                <Button endIcon={<Close />} onClick={onCloseHeaderEvent} variant="text">
                  Close
                </Button>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Hidden smUp={true}>
              {showHeaderText && (
                <Typography component="h1" letterSpacing="2px" variant="labelLarge" sx={{ wordWrap: 'break-word' }}>
                  {headerTitle}
                </Typography>
              )}
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

import * as React from 'react';
import { Button, IconButton, Link as MuiLink, Menu, MenuItem } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Link from 'next/link';
export interface IDropDownMenuProps {
  menuItems: DropDownMenuItem[];
  name: string;
  buttonIcon?: React.ReactElement;
  menuLabel?: React.ReactElement;
  menuOption: MenuOption;
}

export const DropDownMenu: React.FC<IDropDownMenuProps> = props => {
  const buttonLabel = props.name.toLowerCase().replace(/\s/g, '') + '-button';
  const menuName = props.name.toLowerCase().replace(/\s/g, '') + '-menu';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.menuOption == MenuOption.IconOnly && (
        <IconButton
          aria-controls={open ? menuName : undefined}
          aria-label={props.name}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          id={buttonLabel}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          {props.buttonIcon}
        </IconButton>
      )}
      {props.menuOption == MenuOption.IconAndLabel && (
        <Button
          aria-controls={open ? menuName : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          id={buttonLabel}
          onClick={event => setAnchorEl(event.currentTarget)}
          endIcon={<ExpandMore />}
          startIcon={props.buttonIcon}
          sx={{ textAlign: 'left' }}
        >
          {props.menuLabel}
        </Button>
      )}
      {props.menuOption == MenuOption.LabelOnly && (
        <Button
          aria-controls={open ? menuName : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          id={buttonLabel}
          onClick={event => setAnchorEl(event.currentTarget)}
          endIcon={<ExpandMore />}
          sx={{ textAlign: 'left' }}
        >
          {props.menuLabel}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        id={menuName}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
          'aria-labelledby': buttonLabel,
        }}
      >
        {props.menuItems.map(item => {
          return (
            <MenuItem
              key={item.displayLabel.replace(/\s/g, '') + 'key'}
              onClick={() => {
                closeMenu();
                if (item.onClick) {
                  item.onClick();
                }
              }}
            >
              {item.linkPath ? (
                <Link href={item.linkPath} passHref>
                  <MuiLink>{item.displayLabel}</MuiLink>
                </Link>
              ) : (
                <>{item.displayLabel}</>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export type DropDownMenuItem = {
  displayLabel: string;
  linkPath?: string;
  onClick?: () => void;
};

export enum MenuOption {
  IconOnly = 0,
  IconAndLabel,
  LabelOnly,
}

import * as React from 'react';
import { Box, Button, ButtonProps, CircularProgress, Stack, Typography } from '@mui/material';
import { useRoofstockOnChainWeb3 } from '@rs-app/lib/web3';
import { useRouter } from 'next/router';
import { Container } from '../Common/Container';

type BecomeAMemberSectionProps = React.PropsWithChildren<{
  HeadingComponent?: React.ElementType;
}>;

export const BecomeAMemberSection = ({ children, HeadingComponent = 'h1' }: BecomeAMemberSectionProps) => {
  return (
    <Box
      component="section"
      aria-labelledby="roofstockOnChainMember"
      sx={{ background: 'linear-gradient(to bottom, #6EA7C5, #A878BF)' }}
    >
      <Container>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap="2rem">
          <Box paddingTop="4rem" paddingBottom="4rem" width={{ sm: '70%' }}>
            <Typography component={HeadingComponent} id="roofstockOnChainMember" variant="h3">
              Become a Roofstock onChain member
            </Typography>
            <Typography component="p" marginTop="1rem" variant="subtitle2">
              <Typography variant="subtitle2Bold">Mint</Typography> your unique membership token.{' '}
              <Typography variant="subtitle2Bold">Enjoy</Typography> exclusive access to{' '}
              <Typography variant="subtitle2Bold">events, real estate courses,</Typography> and participation in our
              growing web3 real estate <Typography variant="subtitle2Bold">community.</Typography>
            </Typography>
            <Box marginTop="2rem">{children}</Box>
          </Box>
          <Box
            overflow="hidden"
            paddingTop={{ sm: '4rem' }}
            width={{ sm: '25%' }}
            textAlign={{ sm: 'center' }}
            maxHeight="400px"
          >
            <img src="/images/mint-become-a-member.png" alt="" width="121px" height="460px" />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export const MintMembershipTokenSection = () => {
  const { roofstockOnChainMembershipToken } = useRoofstockOnChainWeb3();
  const router = useRouter();
  const [shouldRedirectToProfile, setShouldRedirectToProfile] = React.useState(false);

  React.useEffect(() => {
    // After the user mints their token, redirect them to the Profile page.
    if (shouldRedirectToProfile && roofstockOnChainMembershipToken) {
      router.push('/profile');
    }
  }, [roofstockOnChainMembershipToken, router, shouldRedirectToProfile]);

  if (roofstockOnChainMembershipToken) {
    return null;
  }

  return (
    <Box bgcolor={theme => theme.palette.slate.p800} paddingTop="2rem" paddingBottom="2rem" textAlign="center">
      <Container>
        <MintButton
          color="gradient"
          onClick={() => setShouldRedirectToProfile(true)}
          onMintFailure={() => setShouldRedirectToProfile(false)}
          variant="outlined"
        />
      </Container>
    </Box>
  );
};

type MintButtonProps = Omit<ButtonProps, 'children' | 'color'> & {
  onMint?: () => void;
  onMintFailure?: () => void;
  color?: ButtonProps['color'] | 'gradient';
};

export const MintButton = ({ color, disabled, onClick, onMint, onMintFailure, ...props }: MintButtonProps) => {
  const { currentAccount, mint, mintingStatus, roofstockOnChainMembershipToken, openWeb3Modal } =
    useRoofstockOnChainWeb3();
  const shouldMintAfterConnectingRef = React.useRef(false);
  const mintingRef = React.useRef(false);

  React.useEffect(() => {
    if (shouldMintAfterConnectingRef.current && currentAccount && !mintingRef.current) {
      mintingRef.current = true;
      mint()
        .then(() => {
          onMint?.();
        })
        .catch(reason => {
          onMintFailure?.();

          if (reason instanceof Error) {
            if (reason.message.startsWith('user rejected transaction')) {
              return;
            }

            if (
              reason.message.includes(
                'RoofstockOnChainMembershipToken: This address is already a Roofstock onChain member.'
              )
            ) {
              onMint?.();
              return;
            }

            return;
          }

          throw reason;
        })
        .finally(() => {
          mintingRef.current = false;
          shouldMintAfterConnectingRef.current = false;
        });
    }
  }, [currentAccount, mint, onMint, onMintFailure, roofstockOnChainMembershipToken]);

  return (
    <Button
      {...props}
      disabled={!!roofstockOnChainMembershipToken || mintingStatus === 'loading' || disabled}
      onClick={async event => {
        onClick?.(event);

        if (!currentAccount) {
          shouldMintAfterConnectingRef.current = true;
          openWeb3Modal();
        } else {
          mint()
            .then(() => {
              onMint?.();
            })
            .catch(reason => {
              onMintFailure?.();

              if (reason instanceof Error && reason.message.startsWith('user rejected transaction')) {
                return;
              }

              throw reason;
            });
        }
      }}
      sx={{
        gap: '1rem',
        ...(color === 'gradient' && {
          border: '2px solid',
          borderImage: 'linear-gradient(90deg, #62D4EE 8.65%, #C98BC2 53.12%, #F89DFC 93.5%) 1',
          '&:hover': {
            borderImage: 'linear-gradient(90deg, #62D4EE 8.65%, #C98BC2 53.12%, #F89DFC 93.5%) 1',
          },
        }),
      }}
    >
      {mintingStatus === 'loading' && <CircularProgress color="inherit" size="1.5rem" />}
      {color === 'gradient' ? (
        <Typography
          component="span"
          color="transparent"
          fontSize="1.125rem"
          fontWeight={800}
          sx={{ background: 'linear-gradient(to right, #9CB9E2, #C98BC2, #F89DFC)', backgroundClip: 'text' }}
        >
          Mint membership token
        </Typography>
      ) : (
        <span>Mint membership token</span>
      )}
    </Button>
  );
};

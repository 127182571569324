export enum EventProduct {
  OnChain = 'onChain',
}

export enum EventItem {
  Page = 'Page',
  Link = 'Link',
  Logo = 'Logo',
  Button = 'Button',
  Snippet = 'Snippet',
  Tab = 'Tab',
  Slider = 'Slider',
  Toggle = 'Toggle',
  Photo = 'Photo',
  PhotoScroll = 'PhotoScroll',
  Filter = 'Filter',
  PropertyCard = 'PropertyCard',
  Form = 'Form',
}

export enum EventAction {
  Clicked = 'Clicked',
  Touched = 'Touched',
  Viewed = 'Viewed',
  Moved = 'Moved',
  Changed = 'Changed',
  Submitted = 'Submitted',
}

export enum EventSection {
  HomePage = 'Home Page',
  PrimaryOfferings = 'Primary Offerings page',
  SecondaryOfferings = 'Secondary Offerings page',
  PropertyDetailsPage = 'Property Details Page',
  PropertyOfferingCard = 'Property Offering Card',
  CheckoutPage = 'Checkout Page',
  OrdersPage = 'Orders Page',
  OrdersDetailPage = 'Order Details Page',
  OrderTaskTracker = 'Order Task Tracker',
  Dashboard = 'Dashboard',
  StatementsTaxDocs = 'Statements/Tax Docs',
  Header = 'Header',
  InvestmentAcctInfo = 'Investment Acct Info',
  AccountProfile = 'Account & Profile',
  OfferingDocuments = 'Offering Documents',
  CongratsPage = 'Congrats Page',
  BankAccountsPage = 'Bank Accounts Page',
  AccountPage = 'Account Page',
  ProfilePage = 'Profile Page',
  LeftNavBar = 'Left Nav Bar',
  Footer = 'Footer',
  SignUp = 'SignUp Page',
  Authentication = 'Authentication',
}

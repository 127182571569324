import * as React from 'react';
import { Box, Hidden } from '@mui/material';
import { MinimalHeader } from './MinimalHeader';
import { ToolbarMain } from './Toolbar';
import { ToolbarMobile } from './Toolbar/ToolbarMobile';

type HeaderProps = {
  headerText?: string;
  hideCloseButton?: boolean;
  minimalHeader?: boolean;
  onCloseMinimalHeader: () => void;
  showHeaderText?: boolean;
};

export const Header = ({
  headerText,
  hideCloseButton,
  minimalHeader,
  onCloseMinimalHeader,
  showHeaderText,
}: HeaderProps) => {
  if (minimalHeader) {
    return (
      <MinimalHeader
        onCloseHeaderEvent={onCloseMinimalHeader}
        hideCloseButton={hideCloseButton}
        showHeaderText={showHeaderText}
        headerTitle={headerText}
      />
    );
  }

  return (
    <Box
      bgcolor={theme => theme.palette.slate.p900}
      component="header"
      role="banner"
      display="flex"
      alignItems="center"
      minHeight="100px"
      position="sticky"
      top={0}
      width="100%"
      zIndex={theme => theme.zIndex.appBar}
    >
      <Hidden mdDown>
        <ToolbarMain />
      </Hidden>
      <Hidden mdUp>
        <ToolbarMobile />
      </Hidden>
    </Box>
  );
};

import { Box, Link as MuiLink, Typography } from '@mui/material';
import { publicConfig } from '@rs-app/lib/config';
import { useRoofstockOnChainWeb3 } from '@rs-app/lib/web3';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Container } from '../Common/Container';
import { MintButton } from '../Mint';

export const BecomeAMemberBanner = () => {
  const router = useRouter();
  const { roofstockOnChainMembershipToken } = useRoofstockOnChainWeb3();

  if (roofstockOnChainMembershipToken) {
    return null;
  }

  return (
    <Box sx={{ background: 'linear-gradient(90deg, #232A35 6.97%, #A878BF 93.84%)' }}>
      <Container>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          gap="1.25rem"
          padding="1rem 0"
        >
          <Box alignSelf="flex-start">
            <img
              src={`${publicConfig.imagesFolder}/roofstock-onchain-membership-token-490x400px.png`}
              alt=""
              width="140px"
            />
          </Box>
          <Box>
            <Typography component="p" variant="subtitle2Semibold">
              Become a Roofstock onChain Member
            </Typography>
            <Typography marginTop="0.5rem" variant="body1">
              <Typography variant="body1Bold">Connect</Typography> your wallet.{' '}
              <Typography variant="body1Bold">Mint</Typography> your unique membership token.{' '}
              <Typography variant="body1Bold">Enjoy</Typography> personalized benefits on your home onChain journey.
              <br />
              <Link href="/mint" passHref>
                <MuiLink variant="body1Bold">Learn more.</MuiLink>
              </Link>
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Box flexShrink={0} alignSelf={{ xs: 'flex-start', sm: 'center' }}>
            <MintButton onMint={() => router.push('/profile')} variant="contained" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
